// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-stocks-tsx": () => import("./../../../src/pages/stocks.tsx" /* webpackChunkName: "component---src-pages-stocks-tsx" */),
  "component---src-pages-success-order-tsx": () => import("./../../../src/pages/success-order.tsx" /* webpackChunkName: "component---src-pages-success-order-tsx" */),
  "component---src-pages-ua-about-tsx": () => import("./../../../src/pages/ua/about.tsx" /* webpackChunkName: "component---src-pages-ua-about-tsx" */),
  "component---src-pages-ua-cart-tsx": () => import("./../../../src/pages/ua/cart.tsx" /* webpackChunkName: "component---src-pages-ua-cart-tsx" */),
  "component---src-pages-ua-catalog-tsx": () => import("./../../../src/pages/ua/catalog.tsx" /* webpackChunkName: "component---src-pages-ua-catalog-tsx" */),
  "component---src-pages-ua-contacts-tsx": () => import("./../../../src/pages/ua/contacts.tsx" /* webpackChunkName: "component---src-pages-ua-contacts-tsx" */),
  "component---src-pages-ua-delivery-tsx": () => import("./../../../src/pages/ua/delivery.tsx" /* webpackChunkName: "component---src-pages-ua-delivery-tsx" */),
  "component---src-pages-ua-error-tsx": () => import("./../../../src/pages/ua/error.tsx" /* webpackChunkName: "component---src-pages-ua-error-tsx" */),
  "component---src-pages-ua-index-tsx": () => import("./../../../src/pages/ua/index.tsx" /* webpackChunkName: "component---src-pages-ua-index-tsx" */),
  "component---src-pages-ua-partners-tsx": () => import("./../../../src/pages/ua/partners.tsx" /* webpackChunkName: "component---src-pages-ua-partners-tsx" */),
  "component---src-pages-ua-stocks-tsx": () => import("./../../../src/pages/ua/stocks.tsx" /* webpackChunkName: "component---src-pages-ua-stocks-tsx" */),
  "component---src-pages-ua-success-order-tsx": () => import("./../../../src/pages/ua/success-order.tsx" /* webpackChunkName: "component---src-pages-ua-success-order-tsx" */),
  "component---src-pages-ua-vacancies-tsx": () => import("./../../../src/pages/ua/vacancies.tsx" /* webpackChunkName: "component---src-pages-ua-vacancies-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-stock-tsx": () => import("./../../../src/templates/stock.tsx" /* webpackChunkName: "component---src-templates-stock-tsx" */),
  "component---src-templates-sub-category-tsx": () => import("./../../../src/templates/subCategory.tsx" /* webpackChunkName: "component---src-templates-sub-category-tsx" */)
}

